import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../Shared/Service/company.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-watchlist-news',
  templateUrl: './watchlist-news.component.html',
  styleUrls: ['./watchlist-news.component.css']
})
export class WatchlistNewsComponent implements OnInit {
  showNewsPopup = false;
  selectedNews;
  htmlComponent = '';
  slug = '';
  symbol: string;
  pageselect: any = 1;
  neswlimit: any = 25;
  Filterbynews: any = [];
  datefilter: any = '';
  manicUrl = 'https://manicmarket.com/';
  date: any;
  newslug: any;
  url: any;
  facebookShareUrl: any;
  description = 'Check out this link: ';
  tags = '';
  twitterShareLink: any;
  linkedInshereLink: any;
  absoluteUrl;

  constructor(
    private readonly _companyService: CompanyService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {
    this.getContent();
  }

  public getContent() {
  
  }
  
  ngOnInit(): void { 
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.symbol = this._route.snapshot.params.symbol;
      this.slug = this._route.snapshot.params.slug;
       if (this._route.snapshot.params.slug) {
        this.getnewsdetails(this.slug);}
    });
  }
 
  closeNews() {
    this.showNewsPopup = false;
    this.selectedNews = null;
    this._router.navigate(['watchlist']);
  }
  gotoNews() {
    this.showNewsPopup = false;
    this.selectedNews = null;
    this._router.navigate(['company-profile/' + this.symbol]);
  }

  getnewsdetails(slug) {
    this._companyService.CompanyNewsDetail(slug).subscribe(responseData => {
      if (responseData.isSuccess) {
        this.selectedNews = responseData.data;
        this.newslug = responseData.data.slug;
        this.date = responseData.data.date;
        this.showNewsPopup = true;
      }
    }, error => {
    });
  }
// ------ Social Media Handler Code Start ------ //
fbLink() {
  this.url = this.manicUrl + 'watchlist' + '/' + this.symbol + '/' + 'watchlist-news' + '/' + this.date + '/' + this.slug;
  this.facebookShareUrl = 'https://www.facebook.com/sharer.php?u=' + this.url;
  window.open(this.facebookShareUrl, '_blank');
}
twitterLink() {
  this.url = this.manicUrl + 'watchlist' + '/' + this.symbol + '/' + 'watchlist-news' + '/' + this.date + '/' + this.slug;
  this.twitterShareLink = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(this.url) +
  '&text=' + encodeURIComponent(this.description) +
  '&hashtags=' + encodeURIComponent(this.tags);
  window.open(this.twitterShareLink, '_blank');
}
linkedInLink() {
  this.url = this.manicUrl + 'watchlist' + '/' + this.symbol + '/' + 'watchlist-news' + '/' + this.date + '/' + this.slug;
  this.linkedInshereLink = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.url;
  window.open(this.linkedInshereLink, '_blank');
}
shereEmail() {
  const url = this.manicUrl + 'watchlist' + '/' + this.symbol + '/' + 'watchlist-news' + '/' + this.date + '/' + this.slug;
  const absoluteUrl = url;

  const subject = '';
  const body = '' + absoluteUrl;

  const mailtoLink = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
  window.location.href = mailtoLink;
}
copyLink() {
  this.url = this.manicUrl + 'watchlist' + '/' + this.symbol + '/' + 'watchlist-news' + '/' + this.date + '/' + this.slug;
  const absoluteUrl = this.url;

  const tempInput = document.createElement('input');

  tempInput.value = absoluteUrl;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
}

// ------ Social Media Handler Code Start ------ //
}
