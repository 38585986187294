<div class="my_account_main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="login_warp forgotpass">
          <div class="login_head">
            <h3>Forgot Password</h3>
            <p>Enter the e-mail address associated with your account and we'll send you a link reset your password.</p>
          </div>
          <div class="login-body">
            <div class="form-group">
              <label>Enter e-mail address</label>
              <input class="form-control" [(ngModel)]="email" type="text" >
            </div>
            <div class="d-flex d-flex justify-content-center">
              <div class="control_btns">
                <button (click)="forgetPassword()" class="btn btn-warning">Submit</button>
              </div>
              <div class="control_btns ml-4">
                <button (click)="gotologin()" class="btn">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="my_account_main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="account_edit_inner">
            <div class="profile_edit_form">
                <div class="form-group">
                    <label class="sup_label">Enter  mail</label>
                     <input [(ngModel)]="email" type="text" placeholder="Enter mail">
                  </div>
                  <div class="control_btns">
                    <button (click)="forgetPassword()" class="btn-success">submit</button>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div> -->