<div *ngIf="showNewsPopup" class="backdrop"></div>
<div *ngIf="showNewsPopup" class="jw-modal newsdetailpopup">
  <div class="jw-modal-body rounded-0">
    <div  class="newspopup">
      <div class="top-row">
        <button (click)="closeNews()" #scrollTarget class="row-btn">
          <span>&times;</span>
        </button>
      </div>
      <div class="head-cont">
        <div class="info-heading">{{selectedNews?.top_source_name}} - {{selectedNews?.top_date | date:'M.d.yy'}}</div>
        <div class="news-heading">{{selectedNews?.top_title}}</div>
      </div>
      <div class="descrp">{{selectedNews?.top_text}}</div>
      <div class="text-center popup_action_btn" *ngIf="selectedNews.category == 'General'">
        <a [href]="selectedNews?.top_url" target="_blank" class="btn btn-primary" *ngIf="selectedNews.top_type == 'Video'">Continue to Video</a>
        <a [href]="selectedNews?.top_url" target="_blank" class="btn btn-primary" *ngIf="selectedNews.top_type == 'Article'">Continue to full Article</a>
        <a class="btn btn-dark" style="color: #fff;" (click)="closeNews()">See More Market News 
        </a>
      </div>
      <div class="text-center popup_action_btn" *ngIf="selectedNews.category == 'Tickers'">
        <a [href]="selectedNews?.top_url" target="_blank" class="btn btn-primary" *ngIf="selectedNews.top_type == 'Video'">Continue to Video</a>
        <a [href]="selectedNews?.top_url" target="_blank" class="btn btn-primary" *ngIf="selectedNews.top_type == 'Article'">Continue to Article</a>
        <a class="btn btn-dark" style="color: #fff;" (click)="closeNewsto()">See more news about {{symbol}}
        </a>
      </div>
      <div class="nws_in_adss">
      </div>
    </div>
  </div>
</div>