import { Component, ViewChild  } from '@angular/core';
import { SiteConfig } from './Shared/Model/common-model.model';
import { AccountsService } from './Shared/Service/accounts.service';
import { Router } from '@angular/router';
import { TopfiveComponent } from './topfive/topfive.component';

declare const clicktable: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MMM-App';
  siteConfig = new SiteConfig();
  isVisibleLoader = true;
  isMobile = false;
  @ViewChild(TopfiveComponent) topFiveComponent: TopfiveComponent;
  constructor(private readonly _AccountsService: AccountsService,
    public router: Router) {
    this._AccountsService.getLoginUserDetail();
    clicktable();
  }
  ngOnInit(): void {
    // Perform any logic if needed
    // Redirect to the desired path (e.g., '/home')
  }
  setLayout(isSearchBar: boolean = true, isShowLogo: boolean = true, isShowFooter = true) {
    this._AccountsService.getLoginUserDetail();
    this.siteConfig.isSearchBar = isSearchBar;
    this.siteConfig.isShowLogo = isShowLogo;
    this.siteConfig.isShowFooter = isShowFooter;
  }

  showLoaderEvent(value: boolean = true) {
    this.isVisibleLoader = value;
  }

  // *     top five close function      * //
  close() {
    this.topFiveComponent.edittoplistClose();
  }
}
