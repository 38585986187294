import { Component, OnInit } from '@angular/core';
import { BaseAPIService } from '../../../Shared/Service/base-api.service';
import { CompanyService } from '../../../Shared/Service/company.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WatchListService } from '../../../Shared/Service/watch-list.service';
import { AppSetting } from '../../../Shared/Model/app-setting.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-new-news-popup',
  templateUrl: './new-news-popup.component.html',
  styleUrls: ['./new-news-popup.component.css']
})
export class NewNewsPopupComponent implements OnInit {
  showNewsPopup = false;
  selectedNews: any;
  htmlComponent = '';
  newsSlug = '';
  top_date: string;
  symbol: string;
  pageselect: any = 1;
  neswlimit: any = 25;
  Filterbynews: any = [];
  datefilter: any = '';
  currentPage: number = 1;
  id: any = [];
  
  constructor(private readonly _companyService: CompanyService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private _baseService: BaseAPIService,
    public base: BaseAPIService,
    private readonly _watchListService: WatchListService,
    private datePipe: DatePipe,
  ) {
      this.getContent();
    }
      public getContent() {
    }
  
  ngOnInit(): void { 
  this._route.paramMap.subscribe((params: ParamMap) => {
    this.top_date=this._route.snapshot.params.top_date
    this.newsSlug=this._route.snapshot.params.newsSlug
      if (this._route.snapshot.params.newsSlug) {
        this.getnewsdetails(this.newsSlug);
      }
    });
  }

  closeNews() {
    this.showNewsPopup = false;
    this.selectedNews = null;
    this._router.navigate(['all-news']);
    if (window.innerWidth <= 768) { 
      this.showNewsPopup = false;
      this.selectedNews = null;
      this._router.navigate(['']);
    }
  }
  
  closeNewsto() {
    this.showNewsPopup = false;
    this.selectedNews = null;
    this._router.navigate(['company-profile/' + this.symbol]);
  }
  getnewsdetails(slug) {
    this._companyService.CompanyNewsTicker(slug).subscribe(responseData => {
      if (responseData.success) {
        this.id = responseData.response.id;
        this.selectedNews = responseData.response;
        this.symbol = responseData.response.top_symbols_or_tickers;
        this.showNewsPopup = true;
      }
    }, error => {
    });
  }

}
