<div class="my_account_main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="login_warp chngpass">
          <div class="login_head">
            <h3>Change Password</h3>
            <p>Make sure your password.</p>
          </div>
          <div class="login-body">
              <div class="form-group">
                <label>Enter  Password</label>
                <input class="form-control" [(ngModel)]="password" type="text" placeholder="Enter Password">
              </div>
              <div class="control_btns">
                <button (click)="changePassword()" class="btn btn-warning">submit</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="my_account_main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="account_edit_inner">
            <div class="profile_edit_form">
              <div class="form-group">
                  <label class="sup_label">Enter  Password</label>
                    <input [(ngModel)]="password" type="text" placeholder="Enter Password">
              </div>
              <div class="control_btns">
                <button (click)="changePassword()" class="btn-success">submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div> -->