<div class="jw-modal">
    <!-- <div class="addimg img1"> 
        <img  width="180px" src="../../assets/img/lg_vtl_add.jpg" alt="">
    </div>  -->
    <div class="jw-modal-body">
        <ng-content></ng-content>
    </div>
    <!-- <div class="addimg img2"> 
        <img  width="180px" src="../../assets/img/lg_vtl_add2.jpg" alt="">
    </div>  -->
</div>