import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSuffix'
})
export class DateSuffixPipe implements PipeTransform {
  transform(value: any): string {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    if (isNaN(value.getTime())) {
      return '';
    }

    const day = value.getDate();
    const suffix = this.getDateSuffix(day);

    return `${value.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}${suffix}`;
  }

  private getDateSuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return 'th';
    }

    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
