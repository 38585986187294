import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as protobuf from 'protobufjs';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private ws: W3CWebSocket;
  private readonly url = 'wss://streamer.finance.yahoo.com';
  private readonly messageSubject = new Subject<any>();
  private Yaticker: any;

  public messages$ = this.messageSubject.asObservable();

  constructor() {}

  // company detail servies
  private async initWebSocket(symbol: string) {
    try {
      // Load protobuf schema
      const root = await protobuf.load('/assets/YPricingData.proto');
      this.Yaticker = root.lookupType('yaticker');

      // Initialize WebSocket
      this.ws = new W3CWebSocket(this.url);

      this.ws.onopen = () => {
        console.log('connected');
        this.ws.send(JSON.stringify({ subscribe: [symbol] }));
        
      };

      this.ws.onclose = () => {
        console.log('disconnected');
        this.initWebSocket(symbol);
      };

      this.ws.onmessage = (event: any) => {
        const binaryData = atob(event.data as string);
        const bytes = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          bytes[i] = binaryData.charCodeAt(i);
        }
        const decodedMessage = this.Yaticker.decode(bytes);
        this.messageSubject.next(decodedMessage);
      };
    } catch (error) {
      console.error('Error initializing WebSocket:', error);
    }
  }

  public subscribeToSymbol(symbol: string) {
    this.initWebSocket(symbol);
  }
}
