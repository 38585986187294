import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { UserViewModel } from '../Shared/Model/account.model';
import { CommonHelperService } from '../Shared/Service/common-helper.service';
import { webSocket } from 'rxjs/webSocket';
import { websocketurl } from 'src/environments/environment';
import { AccountsService } from './../Shared/Service/accounts.service';
import { WatchListService } from 'src/app/Shared/Service/watch-list.service';
import { LoaderService } from 'src/app/Shared/Helpers/loader.service';
import { AppComponent } from 'src/app/app.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragMove } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { YahooWebsocketService } from 'src/app/Shared/Service/yahoo-websocket.service';

interface ReplacementItem {
  symbol : string;
}

@Component({
  selector: 'app-topfive',
  templateUrl: './topfive.component.html',
  styleUrls: ['./topfive.component.css']
})
export class TopfiveComponent implements OnInit {
  dragtopsix = true;
  width = 160;
  oldY = 0;
  grabber = false;
  PriceSocket: any;
  login: any;
  eventSource: any;
  edittopname: any = false;
  TopSocketdata: any;
  TopSocketdata2: any;
  yahooapiData: any;
  topfivesymbol: any = [];
  testarray: any = [];
  changeSocket: any;
  changeFinance:any[] = [];
  preMarket: any = [];
  postMarket: any = [];
  Watchlistsymbollist: any;
  yahooData: any;
  apitopShow = false;
  websocketSHow = false;
  replacementArray: ReplacementItem[] = [
    {symbol : 'RUS'},
    {symbol : 'S&P'},
    {symbol : 'NAS'},
    {symbol : 'DJI'},
    {symbol : 'US05Y'},
    {symbol : 'VIX'},
    {symbol : '10Y'},
    {symbol : 'US30Y'},
  ];
  isAuthenticated: Observable<boolean>;
  userDetail: Observable<UserViewModel>;
  _userDetail = new UserViewModel();
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;
  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };
  isAnotherFunctionCalled

  constructor(public readonly _accountService: AccountsService,
    private readonly _watchListService: WatchListService,
    private readonly _commonService: CommonHelperService,
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService,
    private readonly appCoponent: AppComponent,
    public datepipe: DatePipe,
    private webSocketService: YahooWebsocketService,
    private router: Router,
  ) {
    this.userDetail = this._accountService.userDetail;
    this._accountService.isLoggedIn.subscribe(data => {
      this.login = data;
    });
    this._accountService.istopfive.subscribe(data => {
      this.getwebsocketdata()
    });
  }
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
      return;
    }
    this.resizer(event.clientX - this.oldY);
    this.oldY = event.clientX;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false;
  }

  // @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.grabber = true;
    this.oldY = event.clientX;
  }

  resizer(offsetX: number) {
    this.width += offsetX;
  }

  ngOnInit(): void {
    this.userDetail.subscribe(data => {
      this._userDetail = data;
    });
    // this.getwebsocketdata()
  }
  ngAfterViewChecked(): void {
    
    this.loaderService.windowload.subscribe((res) => {
      this.cdRef.detectChanges();
    });
  }
  edittoplist() {
    this.edittopname = !this.edittopname;
  }
  edittoplistClose() {
    this.edittopname = false;
  }
  getwebsocketdata() {
    this.TopSocketdata = [];
    this.TopSocketdata2 = [];
    this.topfivesymbol = [];
    this.testarray = [];
    this.PriceSocket && this.PriceSocket.complete();
    this._watchListService.GetWatchListTop().subscribe(response => {
      if (response.isSuccess) {
        this.TopSocketdata = response?.data;
        this.TopSocketdata2 = response?.data;
        this.yahooapiData = response?.data;
        this.topfivesymbol = response?.symbol;
        this.apitopShow = true;
        this.websocketSHow = false;
        if(this.topfivesymbol) {
          this.preMarket = response?.data[this.topfivesymbol[0]]?.pre_market_open;
          this.postMarket = response?.data[this.topfivesymbol[0]]?.post_market_open;
        }
        this.testarray = Object.keys(response.data)
        if(Object.keys(response.data).length>0) {
          this.yahoowebSocket();
        }
      }
    });
  }

  navigateAndReload(symbol: string): void {
    // Navigate to the route
    this.router.navigate(['/company-profile', symbol]).then(() => {
      // Force reload of the window
      window.location.reload();
    });
  }
  
  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;
    this.dragDropInfo = { dragIndex, dropIndex };
    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);
      moveItemInArray(this.testarray, dragIndex, dropIndex);
      this.dragwatchlistlist()
    }
  }
  dragwatchlistlist() {
    this.appCoponent.showLoaderEvent(false);
    this._watchListService.TopfiveindexDrag(this.testarray).subscribe(response => {
      this.appCoponent.showLoaderEvent(true);
      if (response.isSuccess) {
        for (let i = 0; i < this.testarray.length; i++) {
          document.getElementById("topfiveid" + i).style.removeProperty('display')
        }
      } else {
        this._commonService.showErrorAlert(response.message);
      }
    });
  }
  dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;
    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );
    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;
    if (!receiverElement) {
      return;
    }
    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }
  dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  yahoowebSocket() {
    this.yahooData = {};
    this.webSocketService.subscribeToSymbol(this.topfivesymbol);
    this.webSocketService.messages$.subscribe((message) => {
      if (message?.id && message?.changePercent !== undefined) {
        this.yahooData[message?.id] = message;
        this.apitopShow = false;
        this.websocketSHow = true;
        // console.log('topFive message ---->', message);
      }
      if (!this.isAnotherFunctionCalled) {
        this.getpricewebsocket();
        this.isAnotherFunctionCalled = true;
      }
    });
  }

  stockpriceStart(message: any) {
    if (message?.marketHours != 0 && message?.marketHours != 2 && message?.marketHours != 1) {
      
    } else if (message?.marketHours == 0) {
      this.getpricewebsocket();
    } else if (message?.marketHours == 2) {
      this.getpricewebsocket();
    } else if (message?.marketHours == 1) {
      this.getpricewebsocket();
    } else {
    }
  }

  getpricewebsocket() {
    this.PriceSocket = webSocket(`${websocketurl.url}/ws/stock-price/${this.topfivesymbol}/`);
    this.PriceSocket.subscribe(
      (msg) => {

        for (let key of Object.keys(this.TopSocketdata)) {
          if (this.TopSocketdata[key]?.symbol == msg?.message[key]?.symbol?.symbol) {
            this.TopSocketdata[key].latestPrice = msg?.message[key]?.company_quote_data[0]?.latestPrice;
            this.TopSocketdata[key].latestVolume = msg?.message[key]?.company_quote_data[0]?.latestVolume;
            this.TopSocketdata[key].changePercent = msg?.message[key]?.company_quote_data[0]?.changePercent;
            this.TopSocketdata[key].change = msg?.message[key]?.company_quote_data[0]?.change;
            this.TopSocketdata[key].avgTotalVolume = msg?.message[key]?.company_quote_data[0]?.avgTotalVolume;
            this.TopSocketdata[key].postMarketChange = msg?.message[key]?.postMarketChange;
            this.TopSocketdata[key].postMarketChangePercent = msg?.message[key]?.postMarketChangePercent;
            this.TopSocketdata[key].postMarketPrice = msg?.message[key]?.postMarketPrice;
            this.TopSocketdata[key].preMarketChange = msg?.message[key]?.preMarketChange;
            this.TopSocketdata[key].preMarketChangePercent = msg?.message[key]?.preMarketChangePercent;
            this.TopSocketdata[key].preMarketPrice = msg?.message[key]?.preMarketPrice;
            this.TopSocketdata2[key].msg = msg?.message[key];
            this.loaderService.window();
            
          }
        }
        setTimeout(() => {
          this.PriceSocket.next({});
        }, 60000)
      },
      (err) => this.handleWebSocketError('priceSocket',err)
    );
    this.PriceSocket.next({});
  }



  handleWebSocketError = (type,error: any) => {
    if(type==='priceSocket'&&error.type=='error') {
      setTimeout(() => {
        this.getpricewebsocket();
      }, 15000)
    }
    if(type==='changeSocket'&&error.type=='error') {
      setTimeout(() => {
        this.changeSocket(this.topfivesymbol)
      }, 15000)
    }
    // Call your function here, passing the error as an argument
  };
  removewtachlist(slug) {
    this._watchListService.removeWatchListTop(slug).subscribe(res => {
      if (res.isSuccess) {
        // this._commonService.showSuccessAlert(res.message);
        this._accountService.changetopfive();
      } else {
        // this._commonService.showErrorAlert(res.message);
      }
    })
  }
}