<div class="main_warper container-fluid">
  <div class="row">
    <!-- <div class="col-md-2" id="sidebar_warper">
        <div class="left_sidebar">
        </div>
      </div> -->
    <div class="main-content col-md-12">
      <div class="page-content">
        <div class="email_confirm" *ngIf="confirmemail">
          <!-- <img src="assets/img/email_confirmation.png"> -->
          <h1>
            Welcome to the Manic Market Community!
          </h1>
          <p>
            You should receive a confirmation email within a few minutes. Please click the link in the email to confirm
            that you are a human!
          </p>
          <span class="d-block mb-1">
            Didn't receive our email?
          </span>
          <a class="d-block" href="javascript:void(0);" (click)="this.resendmail()">
            Resend confirmation email
          </a>
        </div>
        <div class="login_warp" *ngIf="!confirmemail">
          <div class="login_head">
            <h3>Log in with your Manic Market account</h3>  
            <!-- <p>with your Manic Market Account</p> -->
          </div>
          <div class="login-body">
            <div class="socail_login">
              <a href="javascript:void(0);" class="socail_btn" (click)="facebookSignin()"><img src="assets/img/social_btn_fb.png">Continue with Facebook</a>
              <a href="javascript:void(0);" class="socail_btn" (click)="signInWithGoogle()"><img src="assets/img/social_btn_google.png">Continue with Google</a>
                <p class="or_divider"><strong>or</strong></p>
            </div>
            
            <form [formGroup]="frmGroup" (ngSubmit)="onSubmit()" accept-charset="utf-8">
              <div class="form-group">
                <label>E-mail</label>
                <input type="text" name="Username" formControlName="Username" #Username
                  class="form-control" [(ngModel)]="model.Username">
                <div class="invalid" style="color: red;"
                  *ngIf="(frmGroup.get('Username').hasError('required') && frmGroup.get('Username').touched)"> E-mail is
                  <strong>required</strong>..!
                </div>
                <!-- <pre class="text-danger">{{this.message}}</pre> -->
              </div>
              <div class="form-group">
                <label>Password</label>
                <input type="password" name="Password" formControlName="Password" #Password
                   class="form-control" [(ngModel)]="model.Password">
                <div class="invalid" style="color: red;"
                  *ngIf="(frmGroup.get('Password').hasError('required') && frmGroup.get('Password').touched)">
                  Password is <strong>required</strong>..! </div>
                  <!-- password incorrect <p class="text-danger mt-2">{{message}}</p> -->
              </div>
              <div class="xs_loginfoot">
                <div class="button-group">
                  <button type="submit" class="btn btn-warning">Log In</button>
                  <a routerLink="/sign-up" class="btn btn-warning">New Account</a>
                </div>
                 <div  class="xs_forgot">
                  <a routerLink="/forget-password" class="forgot_pass">Forgot your password?</a>
                  <div class="form-check">
                    <label class="form-check-label" for="gridCheck">
                      Remember me
                    </label>
                    <input class="form-check-input" type="checkbox" id="gridCheck">
                  </div>
                 </div>
               </div>
              <p class="trems_service">By signing up. I agree to Manic Market's <br/><a routerLink="/terms-conditions">Terms of Service</a>
                and <a routerLink="/privacy-policy">Privacy Policy</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="message_btn_fx">
  <button class="message_btn" type="button" data-toggle="modal" (click)="feedbackInput = '';" data-target="#message_bottom" (click)="gotoLoginPage()">
    <img src="../../assets/img/asset.png">
  </button>
</div>

<!-- <div class="modal fade add_queto_modal message_bottom" id="message_bottom" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md mob" role="document">
    <div class="modal-content" *ngIf="isLoggedIn">
      <div class="modal-header">
      <h2>We’d love to hear from you as we continue to improve our product!</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <form>
        <div class="form_inner">
          <textarea [(ngModel)]="feedbackInput" class="form-control" placeholder="Please let us know your thoughts..."></textarea>
        </div>
          <div class="add_termactin">
            <button type="button" data-dismiss="modal" [disabled]="!feedbackInput" aria-label="Close" (click)="saveFeedback()" class="btn btn-success">Submit</button>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-secondary">Cancel</button>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-content" *ngIf="!isLoggedIn">
      <div class="modal-header">
        <h2>You are not logged in . You must be first login. </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="add_termactin">
          <button type="button" data-dismiss="modal" aria-label="Close"
          [routerLink]="['/login']" class="btn btn-success">Login</button>
        </div>
      </div>
    </div>
  </div>
</div> -->